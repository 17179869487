import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import * as React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

type CustomTooltipProps = {
  tickFormatter?: (value: number) => string;
  label?: number;
  payload?: Payload<string | number | (string | number)[], string | number>[] | undefined;
  active?: boolean;
};

function CustomTooltip({
  active,
  payload,
  label,
  tickFormatter,
}: CustomTooltipProps) {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ minWidth: 100 }}>
        <CardContent>
          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
            {label}
          </Typography>
          {tickFormatter ? <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{tickFormatter(payload[0].value as number)}</Typography> : payload[0].value}
        </CardContent>
      </Card>
    );
  }

  return null;
}

interface LineChartComponentProps {
  data: { [key: string]: string | number }[];
  dataKeyX: string;
  dataKeyLine: string;
  v2?: boolean;
  tickFormatter?: (value: number) => string;
}

export default function SingleLineChart({
  data,
  dataKeyX,
  dataKeyLine,
  v2,
  tickFormatter,
}: LineChartComponentProps): JSX.Element {
  const uniqueDates = [] as { [key: string]: string | number }[];
  data.reduce((acc, curr) => {
    if (acc[curr[dataKeyX] as number] === undefined) {
      uniqueDates.push(curr);
    }
    return {
      ...acc,
      [curr[dataKeyX] as string]: curr.value,
    };
  }, {} as { [key: string]: string | number });

  const tickFormatterFn = (value: number) => {
    if (tickFormatter) {
      return [1, 4, 7].includes(value) ? tickFormatter(value) : '';
    }
    return `${value}`;
  };

  return (
    <ResponsiveContainer width='100%' height={150}>
      <LineChart data={uniqueDates} margin={{ left: v2 ? 55 : 0, top: v2 ? 10 : 5, bottom: 0 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey={dataKeyX} fontSize={12} height={20} />
        <YAxis
          width={20}
          axisLine={false}
          domain={v2 ? [1, 7] : [0, 10]}
          interval={0}
          fontSize={12}
          tickMargin={10}
          ticks={
            v2
              ? Array.from({ length: 7 }, (_, i) => i + 1)
              : Array.from({ length: 6 }, (_, i) => i * 2)
          }
          tickFormatter={tickFormatterFn}
        />
        <Tooltip content={<CustomTooltip tickFormatter={tickFormatter} />} wrapperStyle={{ color: 'black' }} />
        <Line type='monotone' dataKey={dataKeyLine} />
      </LineChart>
    </ResponsiveContainer>
  );
}

import { PaletteMode } from '@mui/material';
import { blueGrey, grey, purple, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      logoHeight: string;
      logoWidth: string;
      appBarHeight: string;
      sidebarWidth: string;
      smSidebarWidth?: string;
      backgroundColor: string;
      darkTextPrimary?: string;
      tooltipBG?: string;
      colors: { [key: string]: string };
      waypoints: Array<string>;
    };
  }

  interface ThemeOptions {
    custom?: {
      logoHeight?: string;
      logoWidth?: string;
      appBarHeight?: string;
      sidebarWidth?: string;
      smSidebarWidth?: string;
      backgroundColor?: string;
      darkTextPrimary?: string;
      tooltipBG?: string;
      colors: { [key: string]: string };
      waypoints: Array<string>;
  };
}
}

const htmlFontSize = 18;
const fontSize = 16;

const Theme = createTheme({
  custom: {
    logoHeight: '32px',
    logoWidth: '150px',
    appBarHeight: '64px',
    sidebarWidth: '256px',
    smSidebarWidth: '64px',
    backgroundColor: '#F4F4F4',
    darkTextPrimary: '#33739D',
    tooltipBG: '#F1F1F1',
    colors: {
      lightTextPrimary: '#1E2731',
      lightTextSecondary: '#787878',
      lightSuccessMain: '#4A7F4B',
      lightSecondaryMain: '#CAA360',
      lightErrorMain: '#CC3B3B',
      primaryMain: '#33739D',
      lightTertiaryMain: '#8E4C7D',
      backgroundColorSecondary: '#E9E9E9',
      white: '#FFFFFF',
    },
    // Rotating colors for waypoint tracking
    waypoints: [
      '#33739D',
      '#CAA360',
      '#8E4C7D',
      '#32A597',
      '#4A7F4B',
      '#E5772C',
      '#4C4C4C',
      '#CC3B3B',
      '#2EA09A',
    ],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1024,
      xl: 1920,
    },
  },
  palette: {
    mode: 'light' as PaletteMode,
    primary: {
      main: '#136288',
    },
    secondary: {
      main: purple[500],
    },
    error: {
      light: red[800],
      main: red[800],
    },
  },
  typography: {
    htmlFontSize,
    fontSize,
    fontFamily: "'Roboto', sans-serif",
    h1: {
      fontSize: '3.75rem',
      fontWeight: 300,
      lineHeight: '1.2',
    },
    h2: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: '400',
      lineHeight: '1.167',
    },
    h4: {
      fontSize: '2.125rem',
      fontWeight: '400',
      lineHeight: '1.235',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: '500',
      lineHeight: '1.6',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      color: blueGrey[200],
      fontSize: '1rem',
    },
    subtitle2: {
      fontSize: '0.7rem',
    },
    caption: {
      color: grey[500],
      fontSize: '0.7rem',
    },
    button: {
      borderRadius: '3px',
      padding: '6px 12px',
      textTransform: 'none',
    },
  },
  components: {
    MuiTextField: { defaultProps: { autoComplete: 'off' } },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          display: 'none',
        },
      },
    },
  },
});

export default Theme;

import React from 'react';
import { PatientObj, ThirtyDayStatus } from 'pages/Dashboard/types/patient.types';
import { Box, Chip, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { ReactComponent as NoData } from 'assets/NotEnoughData.svg';
import ExpandedRowView from 'pages/Dashboard/components/ExpandedView';
import { CheckCircle, ExpandMoreOutlined, TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { formatRelativeDate } from 'utils/dateUtils';
import { capitalize } from 'utils/appUtils';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import Theme from 'theme';
import { useHttp } from 'hooks/use-fetch';

interface ReadyPatientComponentProps {
  patient: PatientObj;
  onAccordionChange: (patientId: string) => void;
  expanded: boolean;
}
function GetThirtyDayProgress({
  thirtyDayProgress,
}: {
  thirtyDayProgress: PatientObj['thirtyDayStatus'];
}) {
  switch (thirtyDayProgress) {
    case 'UP':
      return <TrendingUp sx={{ color: '#4A7F4B' }} />;
    case 'DOWN':
      return <TrendingDown sx={{ color: '#CC3B3B' }} />;
    case 'NEUTRAL':
      return <TrendingFlat sx={{ color: Theme.custom.colors.lightTextSecondary }} />;
    default:
      return <NoData />;
  }
}
function GetHealthRecordStatus({
  healthRecordStatus,
}: {
  healthRecordStatus: PatientObj['healthRecordStatus'];
}) {
  switch (healthRecordStatus) {
    case 'IN_PROGRESS':
      return (
        <Chip
          label='in progress'
          sx={{
            backgroundColor: '#E9F9EA',
          }}
        />
      );
    case 'PRESENT':
      return (
        <CheckCircle sx={{ color: '#4A7F4B' }} />
      );
    default:
      return (
        <Chip
          label='in progress'
          sx={{
            backgroundColor: '#E9F9EA',
          }}
        />
      );
  }
}
const TextMap: { [key: string]: string } = {
  [ThirtyDayStatus.UP]:
    'An improvement of 2 or more in any mood vector, without significant declines in others',
  [ThirtyDayStatus.DOWN]: 'A decline of 2 or more in any mood vector',
  [ThirtyDayStatus.NEUTRAL]: 'A change of no more than 1 in all mood vectors',
  [ThirtyDayStatus.NO_DATA]: 'Not enough data to determine a change',
  [ThirtyDayStatus.NOT_ENOUGH_DATA]: 'Not enough data to determine a change',
};

const TextMapV2: { [key: string]: string } = {
  [ThirtyDayStatus.UP]: 'An improvement in pleasantness or energy, without a decline in the other',
  [ThirtyDayStatus.DOWN]: 'A decline in pleasantness or energy',
  [ThirtyDayStatus.NEUTRAL]: 'No change in pleasantness or energy',
  [ThirtyDayStatus.NO_DATA]: 'Not enough data to determine a change',
  [ThirtyDayStatus.NOT_ENOUGH_DATA]: 'Not enough data to determine a change',
};
const IconMap: { [key: string]: React.ReactElement } = {
  [ThirtyDayStatus.UP]: <TrendingUp sx={{ color: Theme.custom.colors.lightSuccessMain }} />,
  [ThirtyDayStatus.DOWN]: <TrendingDown sx={{ color: Theme.custom.colors.lightErrorMain }} />,
  [ThirtyDayStatus.NEUTRAL]: (
    <TrendingFlat sx={{ color: Theme.custom.colors.lightTextSecondary }} />
  ),
  [ThirtyDayStatus.NO_DATA]: (
    <TrendingFlat sx={{ color: Theme.custom.colors.lightTextSecondary }} />
  ),
  [ThirtyDayStatus.NOT_ENOUGH_DATA]: (
    <TrendingFlat sx={{ color: Theme.custom.colors.lightTextSecondary }} />
  ),
};

function ToolTipComponent({ thirtyDayStatus, isNewVersionEnabled }: { thirtyDayStatus: PatientObj['thirtyDayStatus'], isNewVersionEnabled: boolean }) {
  const Icon = IconMap[thirtyDayStatus as string];
  const SVGIcon = Icon as React.ReactElement;
  const Map = isNewVersionEnabled ? TextMapV2 : TextMap;
  return (
    <Box sx={{ display: 'flex', gap: '8px', width: 400, alignItems: 'center' }}>
      {SVGIcon}
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body2' fontSize={16}>
          {Map[thirtyDayStatus as string]}
        </Typography>
        <Typography color='grey' fontSize={14}>
          (across the two most recent logs)
        </Typography>
      </Box>
    </Box>
  );
}
function GetBehavioralDataStatus({
  behavioralDataStatus,
}: {
  behavioralDataStatus: PatientObj['behavioralDataStatus'];
}) {
  switch (behavioralDataStatus) {
    case 'PRESENT':
      return <CheckCircle sx={{ color: '#4A7F4B' }} />;
    case 'NO_DATA':
      return (
        <Chip
          label='no data yet'
          sx={{
            backgroundColor: Theme.custom.colors.backgroundColorSecondary,
          }}
        />
      );
    case 'SIGNUP_PENDING':
      return (
        <Chip
          label='signup pending'
          sx={{
            backgroundColor: Theme.custom.colors.backgroundColorSecondary,
          }}
        />
      );
    default:
      return null;
  }
}

function ReadyPatientComponent(props: ReadyPatientComponentProps) {
  const { isNewVersionEnabled } = useHttp();
  const { patient, onAccordionChange, expanded } = props;
  const nameString = `${capitalize(patient.name?.firstName ?? '')} ${capitalize(patient.name?.lastName ?? '')}`;
  const handleRowClicked = () => {
    trackMixpanelEvent(MixpanelEventName.PATIENT_QUICK_VIEW_OPENED, {
      patientId: patient.id ?? '',
    });
    onAccordionChange(patient.id ?? '');
  };
  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: Theme.custom.backgroundColor,
            '& td': {
            },
          },
          backgroundColor: expanded ? Theme.custom.colors.white : 'transparent',
          cursor: 'pointer',
          p: 20,
          flexDirection: 'row',
          alignItems: 'center',
          height: 40,
          borderBottom: expanded ? 'none' : '1px solid rgba(224, 224, 224, 1)',
        }}
        onClick={handleRowClicked}
      >
        <TableCell
          component='th'
          scope='patient'
          sx={{
            color: Theme.custom.darkTextPrimary,
            fontWeight: patient.hasRead ? 600 : 400,
            padding: '4px 8px',
            width: 350,
          }}
        >
          <Typography style={{ cursor: 'pointer', display: 'inline-block' }}>
            {nameString}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            padding: '4px 8px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              sx={{
                textAlign: 'right',
                width: 100,
                mr: 2,
              }}
            >
              {patient.dateAdded ? new Date(patient.dateAdded).toLocaleDateString('en-US') : ''}
            </Typography>
          </Box>
        </TableCell>

        <TableCell sx={{ padding: '4px 8px', textAlign: 'center' }}>
          <GetHealthRecordStatus healthRecordStatus={patient.healthRecordStatus} />
        </TableCell>
        <TableCell sx={{ padding: '4px 8px', textAlign: 'center' }}>
          <GetBehavioralDataStatus behavioralDataStatus={patient.behavioralDataStatus} />
        </TableCell>
        <TableCell
          sx={{
            padding: '4px 8px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              sx={{
                textAlign: 'right',
                width: 100,
                mr: 2,
              }}
            >
              {patient.patientActivity ? formatRelativeDate(new Date(patient.patientActivity)) : ''}
            </Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ padding: '4px 8px', alignSelf: 'flex-start' }}>
          <span
            style={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Tooltip
              placement='top'
              title={(
                <ToolTipComponent
                  thirtyDayStatus={patient.thirtyDayStatus}
                  isNewVersionEnabled={isNewVersionEnabled}
                />
              )}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#F2F2F2', // Tooltip background color
                    color: 'black', // Tooltip text color
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional shadow
                    borderRadius: '4px', // Rounded corners
                    padding: '8px', // Padding inside the tooltip
                    height: 'auto',
                    maxWidth: '400px',
                  },
                },
                arrow: {
                  sx: {
                    color: '#F2F2F2', // Same as tooltip background to match the color
                  },
                },
              }}
              arrow
            >
              <span>
                <GetThirtyDayProgress thirtyDayProgress={patient.thirtyDayStatus} />
              </span>
            </Tooltip>
            <ExpandMoreOutlined
              sx={{
                float: 'right',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
                color: Theme.custom.colors.lightTextSecondary,
              }}
            />
          </span>
        </TableCell>
      </TableRow>

      {expanded ? <ExpandedRowView id={patient.id ?? ''} patient={patient} /> : null}
    </>
  );
}

export default ReadyPatientComponent;
